import React from 'react';
import { Disclosure } from '@headlessui/react';
import SearchBar from '../UI/SearchBar';
import Notifications from '../Notifications';
import UserProfileDropdown from '../UserProfileDropdown';
import HeaderLogo from '../HeaderLogo';
import TopNavigationMenu from '../Navigation';
import MobileNavigationMenu from '../MobileNavigationMenu';
import MobileNavigationContent from '../MobileNavigationContent';
import { navigation } from '../../data/navigation';

export const AppBar = (props) => {
    return (
        <Disclosure as="nav" key="appbar" className="bg-atlas-primary w-full z-50">
            {({ open }) => (
                <>
                    <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                        <div className="relative flex items-center justify-between h-16">
                            <div className="flex items-center px-2 lg:px-0">
                                <div className="flex-shrink-0">
                                    <HeaderLogo />
                                </div>
                                <div className="hidden lg:block lg:ml-6">
                                    <TopNavigationMenu
                                        open={open}
                                        navigation={navigation}
                                    />
                                </div>
                            </div>
                            <div className="flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end">
                                <SearchBar />
                            </div>
                            <div className="flex lg:hidden">
                                <MobileNavigationMenu open={open} />
                            </div>
                            <div className="hidden lg:block lg:ml-4">
                                <div className="flex items-center">
                                    <div className="mt-2">
                                        <Notifications count={1} />
                                    </div>
                                    <UserProfileDropdown />
                                </div>
                            </div>
                        </div>
                    </div>

                    <MobileNavigationContent
                        open={open}
                        navigation={navigation}
                    />
                </>
            )}
        </Disclosure>
    );
};

export default AppBar;
