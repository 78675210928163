import React from 'react';
import AppBar from '../../components/AppBar';
import { AnimatePresence, motion } from 'framer-motion';
import UserProvider from '../../providers/UserProvider';
import BackendProvider from '../../providers/BackendProvider';
import NotificationProvider from 'src/providers/NotificationProvider';
import NotificationPanel from 'src/components/Notifications/NotificationPanel';

const pageVariants = {
    initial: {
        opacity: 0,
    },
    in: {
        opacity: 1,
    },
    out: {
        opacity: 0,
    },
};

const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3,
};

const Application = ({ children }) => {
    return (
        <AnimatePresence>
            <NotificationProvider>
                <UserProvider>
                    <BackendProvider>
                        <div className="min-h-full">
                            <header className="bg-atlas-background shadow z-10 sticky top-0">
                                <AppBar />
                            </header>
                            <motion.div
                                initial="initial"
                                animate="in"
                                exit="out"
                                variants={pageVariants}
                                transition={pageTransition}
                            >
                                <main className="relative">{children}</main>
                            </motion.div>

                            <NotificationPanel />

                            {/* {banner} */}
                        </div>
                    </BackendProvider>
                </UserProvider>
            </NotificationProvider>
        </AnimatePresence>
    );
};

export default Application;
