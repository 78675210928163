import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

const TopNavigationMenu = ({ open, navigation }) => {
    return (
        <div className="flex space-x-4">
            {navigation.map((item) => (
                <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                        classNames(
                            isActive
                                ? 'bg-atlas-background text-atlas-primary'
                                : 'text-atlas-background hover:bg-gray-700 hover:text-atlas-background',
                            'px-3 py-2 rounded text-md font-medium'
                        )
                    }
                >
                    {item.name}
                </NavLink>
            ))}
        </div>
    );
};

TopNavigationMenu.propTypes = {};

export default TopNavigationMenu;
