import React from 'react';
import Header from 'src/components/Header';
import Application from 'src/layout/Application';
import ThemeActionMenu from './ThemeActionMenu';

const ThemesPage = (props) => {
    return (
        <Application>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <Header title="Themes" actions={<ThemeActionMenu />} />
                <h1>TODO</h1>
            </div>
        </Application>
    );
};

export default ThemesPage;
