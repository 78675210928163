/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';

export const NotificationPopup = ({ item }) => {
    const [show, setShow] = useState(true);

    return (
        <>
            <div
                aria-live="assertive"
                className="fixed inset-0 mt-10 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
            >
                <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    {item && (
                        <Transition
                            key="notification-popup"
                            show={show}
                            as={Fragment}
                            enter="transform ease-out duration-300 transition"
                            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="max-w-sm w-full bg-white shadow-lg rounded pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="p-4">
                                    <div className="flex items-start">
                                        <div className="flex-shrink-0">
                                            {item.type === 'success' && (
                                                <CheckCircleIcon className="h-6 w-6 bg-green-400 text-white rounded-full" aria-hidden="true" />
                                            )}
                                            {item.type === 'warning' && (
                                                <ExclamationCircleIcon className="h-6 w-6 bg-orange-400 text-white rounded-full" aria-hidden="true" />
                                            )}
                                            {item.type === 'error' && (
                                                <XCircleIcon className="h-6 w-6 bg-red-400 text-white rounded-full" aria-hidden="true" />
                                            )}
                                        </div>
                                        <div className="ml-3 w-0 flex-1 pt-0.5">
                                            <p className="text-sm font-medium text-gray-900">
                                                {item.title}
                                            </p>
                                            <p className="mt-1 text-sm text-gray-500 truncate">
                                                {item.body}
                                            </p>
                                        </div>
                                        <div className="ml-4 flex-shrink-0 flex">
                                            <button
                                                type="button"
                                                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                                onClick={() => {
                                                    setShow(false);
                                                }}
                                            >
                                                <span className="sr-only">
                                                    Close
                                                </span>
                                                <XCircleIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    )}
                </div>
            </div>
        </>
    );
};

export default NotificationPopup;
