import React, { Fragment, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import NotificationItem from './NotificationItem';
import { NotificationContext } from 'src/providers/NotificationProvider';
import classNames from 'classnames';
import { XCircleIcon } from '@heroicons/react/24/outline';

export const NotificationPanel = (props) => {
    const { panelOpen, setPanelOpen } = useContext(NotificationContext);

    return (
        <Transition.Root show={panelOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setPanelOpen}>
                <div className="fixed inset-0" />

                <div className="fixed inset-0 overflow-hidden">
                    <div className="absolute inset-0 overflow-hidden">
                        <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                            <Transition.Child
                                as={Fragment}
                                enter="transform transition ease-in-out duration-500 sm:duration-700"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transform transition ease-in-out duration-500 sm:duration-700"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                                    <NotificationContext.Consumer>
                                        {({
                                            notifications,
                                            removeNotification,
                                        }) => (
                                            <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                                                <div className="p-4">
                                                    <div className="flex items-center justify-between">
                                                        <Dialog.Title className="flex flex-row space-x-2 py-2">
                                                            <div className="text-2xl font-bold text-gray-900 ">
                                                                Notifications
                                                            </div>
                                                            <div className="items-center justify-center px-3 py-1 rounded-full font-medium bg-gray-100 text-gray-800">
                                                                {
                                                                    notifications.length
                                                                }
                                                            </div>
                                                        </Dialog.Title>
                                                        <div className="ml-3 flex h-7 items-center">
                                                            <button
                                                                type="button"
                                                                className="rounded-full bg-white text-gray-400 hover:text-gray-600 border font-semibold hover:font-bold border-gray-400 hover:border-gray-600"
                                                                onClick={() =>
                                                                    setPanelOpen(
                                                                        false
                                                                    )
                                                                }
                                                            >
                                                                <span className="sr-only">
                                                                    Close panel
                                                                </span>
                                                                <XCircleIcon
                                                                    className="h-6 w-6"
                                                                    aria-hidden="true"
                                                                />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="border-b border-gray-200">
                                                    <div className="px-6">
                                                        <nav
                                                            className="-mb-px flex space-x-6"
                                                            x-descriptions="Tab component"
                                                        >
                                                            <a
                                                                href="#"
                                                                onClick={(e) =>
                                                                    e.preventDefault()
                                                                }
                                                                className={classNames(
                                                                    true
                                                                        ? 'border-atlas-primary text-gray-800 font-semibold'
                                                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                                                    'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                                                                )}
                                                            >
                                                                Unread
                                                            </a>
                                                            <a
                                                                href="#"
                                                                onClick={(e) =>
                                                                    e.preventDefault()
                                                                }
                                                                className={classNames(
                                                                    false
                                                                        ? 'border-atlas-primary text-atlas-primary'
                                                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                                                    'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                                                                )}
                                                            >
                                                                Read
                                                            </a>
                                                        </nav>
                                                    </div>
                                                </div>
                                                <ul
                                                    role="list"
                                                    className="flex-1 divide-y divide-gray-200 overflow-y-auto"
                                                >
                                                    {notifications.map(
                                                        (item) => (
                                                            <NotificationItem
                                                                key={item.id}
                                                                item={item}
                                                                removeItem={
                                                                    removeNotification
                                                                }
                                                            />
                                                        )
                                                    )}
                                                    <div className="border-b border-gray-200" />
                                                </ul>
                                            </div>
                                        )}
                                    </NotificationContext.Consumer>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default NotificationPanel;
