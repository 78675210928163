import React from 'react';
import DailyActiveUsersStats from './DailyActiveUsersStats';
import UserStats from './UserStats';

const DashboardStats = () => {
    return (
        <div>
            <UserStats />
            <DailyActiveUsersStats />
        </div>
    );
};

export default DashboardStats;
