import React, { useState } from 'react';
import { RadioGroup, Switch } from '@headlessui/react';
import classNames from 'classnames';

const plans = [
    {
        name: 'Startup',
        priceMonthly: 29,
        priceYearly: 290,
        limit: 'Up to 5 active job postings',
    },
    {
        name: 'Business',
        priceMonthly: 99,
        priceYearly: 990,
        limit: 'Up to 25 active job postings',
    },
    {
        name: 'Enterprise',
        priceMonthly: 249,
        priceYearly: 2490,
        limit: 'Unlimited active job postings',
    },
];
const payments = [
    {
        id: 1,
        date: '1/1/2020',
        datetime: '2020-01-01',
        description: 'Business Plan - Annual Billing',
        amount: 'CA$109.00',
        href: '#',
    },
    // More payments...
];

const BillingSection = (props) => {
    const [selectedPlan, setSelectedPlan] = useState(plans[0]);
    const [annualBillingEnabled, setAnnualBillingEnabled] = useState(true);
    return (
        <section aria-labelledby="billing-history-heading">
            <div className="bg-white pt-6 shadow sm:rounded sm:overflow-hidden mb-4">
                <div className="px-4 sm:px-6">
                    <h2 className="text-2xl font-bold text-gray-700">
                        Plan
                    </h2>
                </div>
                <div className="mt-2 flex flex-col bg-white py-6 px-4 space-y-6 sm:p-4">
                    <RadioGroup value={selectedPlan} onChange={setSelectedPlan}>
                        <RadioGroup.Label className="sr-only">
                            Pricing plans
                        </RadioGroup.Label>
                        <div className="relative bg-white rounded -space-y-px">
                            {plans.map((plan, planIdx) => (
                                <RadioGroup.Option
                                    key={plan.name}
                                    value={plan}
                                    className={({ checked }) =>
                                        classNames(
                                            planIdx === 0
                                                ? 'rounded-tl-md rounded-tr-md'
                                                : '',
                                            planIdx === plans.length - 1
                                                ? 'rounded-bl-md rounded-br-md'
                                                : '',
                                            checked
                                                ? 'bg-gray-100 border-gray-200 z-10'
                                                : 'border-gray-200',
                                            'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none'
                                        )
                                    }
                                >
                                    {({ active, checked }) => (
                                        <>
                                            <span className="flex items-center text-sm">
                                                <span
                                                    className={classNames(
                                                        checked
                                                            ? 'bg-gray-500 border-transparent'
                                                            : 'bg-white border-gray-300',
                                                        // active
                                                            // ? 'ring-2 ring-offset-2 ring-gray-900'
                                                            // : '',
                                                        'h-4 w-4 rounded-full border flex items-center justify-center'
                                                    )}
                                                    aria-hidden="true"
                                                >
                                                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                                                </span>
                                                <RadioGroup.Label
                                                    as="span"
                                                    className="ml-3 font-medium text-gray-900"
                                                >
                                                    {plan.name}
                                                </RadioGroup.Label>
                                            </span>
                                            <RadioGroup.Description
                                                as="span"
                                                className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center"
                                            >
                                                <span
                                                    className={classNames(
                                                        checked
                                                            ? 'text-gray-900'
                                                            : 'text-gray-900',
                                                        'font-medium'
                                                    )}
                                                >
                                                    ${plan.priceMonthly} / mo
                                                </span>{' '}
                                                <span
                                                    className={
                                                        checked
                                                            ? 'text-gray-700'
                                                            : 'text-gray-500'
                                                    }
                                                >
                                                    ($
                                                    {plan.priceYearly} / yr)
                                                </span>
                                            </RadioGroup.Description>
                                            <RadioGroup.Description
                                                as="span"
                                                className={classNames(
                                                    checked
                                                        ? 'text-gray-700'
                                                        : 'text-gray-500',
                                                    'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right'
                                                )}
                                            >
                                                {plan.limit}
                                            </RadioGroup.Description>
                                        </>
                                    )}
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>

                    <Switch.Group as="div" className="flex items-center">
                        <Switch
                            checked={annualBillingEnabled}
                            onChange={setAnnualBillingEnabled}
                            className={classNames(
                                annualBillingEnabled
                                    ? 'bg-gray-500'
                                    : 'bg-gray-200',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors ease-in-out duration-200'
                            )}
                        >
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    annualBillingEnabled
                                        ? 'translate-x-5'
                                        : 'translate-x-0',
                                    'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                )}
                            />
                        </Switch>
                        <Switch.Label as="span" className="ml-3">
                            <span className="text-sm font-medium text-gray-900">
                                Annual billing{' '}
                            </span>
                            <span className="text-sm text-gray-500">
                                (Save 10%)
                            </span>
                        </Switch.Label>
                    </Switch.Group>
                    <div className="pt-8 flex justify-end">
                        <button
                            type="button"
                            className="bg-white py-2 px-4 border border-gray-300 rounded shadow-sm text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-atlas-primary hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
            <div className="bg-white pt-6 shadow sm:rounded sm:overflow-hidden">
                <div className="px-4 sm:px-6">
                    <h2 className="text-2xl font-bold text-gray-700">
                        Billing History
                    </h2>
                </div>
                <div className="mt-6 flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="overflow-hidden border-t border-gray-200">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Date
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Description
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Amount
                                            </th>
                                            {/*
                                    `relative` is added here due to a weird bug in Safari that causes `sr-only` headings to introduce overflow on the body on mobile.
                                    */}
                                            <th
                                                scope="col"
                                                className="relative px-6 py-3 text-left text-sm font-medium text-gray-500"
                                            >
                                                <span className="sr-only">
                                                    View receipt
                                                </span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {payments.map((payment) => (
                                            <tr key={payment.id}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                                    <time
                                                        dateTime={
                                                            payment.datetime
                                                        }
                                                    >
                                                        {payment.date}
                                                    </time>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {payment.description}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    {payment.amount}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <a
                                                        href={payment.href}
                                                        className="text-gray-600 hover:text-gray-900"
                                                    >
                                                        View receipt
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

BillingSection.propTypes = {};

export default BillingSection;
