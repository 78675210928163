import React from 'react';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

const UserStats = () => {
    const stats = [
        {
            name: 'Total Users',
            stat: '3280',
            previousStat: '1081',
            change: '303%',
            changeType: 'increase',
        },
        {
            name: 'Signups',
            stat: '2199',
            previousStat: '843',
            change: '261%',
            changeType: 'increase',
        },
        {
            name: 'User Retention',
            stat: '100%',
            previousStat: '100%',
            change: '0%',
            changeType: 'increase',
        },
        {
            name: 'Failed Logins',
            stat: '556',
            previousStat: '0',
            change: '100%',
            changeType: 'increase',
        },
    ];

    return (
        <dl className="mt-3 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-y-0 md:divide-x">
            {stats.map((item) => (
                <div key={item.name} className="px-4 py-5 sm:p-6">
                    <dt className="text-lg font-normal text-gray-900">
                        {item.name}
                    </dt>
                    <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                        <div className="flex items-baseline text-2xl font-semibold text-atlas-primary tracking-wide">
                            {item.stat}
                            <span className="ml-2 text-sm font-medium text-gray-500">
                                from {item.previousStat}
                            </span>
                        </div>

                        <div
                            className={classNames(
                                item.changeType === 'increase'
                                    ? 'bg-green-100 text-green-800'
                                    : 'bg-red-100 text-red-800',
                                'inline-flex items-baseline px-2.5 py-0.5 rounded text-sm font-medium md:mt-2 lg:mt-0'
                            )}
                        >
                            {item.changeType === 'increase' ? (
                                <ArrowUpIcon
                                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-atlas-green"
                                    aria-hidden="true"
                                />
                            ) : (
                                <ArrowDownIcon
                                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-atlas-red"
                                    aria-hidden="true"
                                />
                            )}

                            <span className="sr-only">
                                {' '}
                                {item.changeType === 'increase'
                                    ? 'Increased'
                                    : 'Decreased'}{' '}
                                by{' '}
                            </span>
                            {item.change}
                        </div>
                    </dd>
                </div>
            ))}
        </dl>
    );
};

export default UserStats;
