import React from 'react';
import Breadcrumbs from '../Breadcrumbs';

const Header = (props) => {
    const { title, subtitle, actions, breadcrumbs } = props;

    return (
        <div className="py-4 lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
                <Breadcrumbs display={breadcrumbs} />

                <h2 className="mt-2 text-2xl font-maisonneuebook uppercase font-bold leading-6 text-gray-800 sm:truncate sm:text-3xl tracking-tight">
                    {title}
                </h2>
                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                    {subtitle}
                </div>
            </div>
            <div className="mt-5 flex lg:mt-0 lg:ml-4">{actions}</div>
        </div>
    );
};

export default Header;
