import Application from '../../layout/Application';
import React from 'react';
import DashboardStats from 'src/components/DashboardStats';
import HomePageSubtitle from './HomePageSubtitle';
import Header from 'src/components/Header';
import HomePageActionMenu from './HomePageActionMenu';

export const HomePage = (props) => {
    return (
        <Application>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <Header
                    title="Users"
                    // subtitle={<HomePageSubtitle />}
                    actions={<HomePageActionMenu />}
                />
                <DashboardStats />
            </div>
        </Application>
    );
};

export default HomePage;
