import React, { Fragment, useContext } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { userNavigation } from 'src/data/navigation';
import { NavLink } from 'react-router-dom';
import { UserContext } from 'src/providers/UserProvider';
import { UserCircleIcon } from '@heroicons/react/20/solid';

const UserProfileDropdown = (props) => {
    const {user, logout} = useContext(UserContext);
    console.log()
    return (
        <Menu as="div" className="ml-4 relative flex-shrink-0 z-50">
            <div>
                <Menu.Button className="bg-atlas-secondary rounded-full flex text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <span className="sr-only">Open user menu</span>
                    {user?.picture === undefined ? (
                        <UserCircleIcon className="h-8 w-8 rounded-full text-atlas-primary hover:text-white" />
                    ) : (
                        <img
                            className="h-8 w-8 rounded-full"
                            src={user?.picture}
                            alt=""
                        />
                    )}
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="z-50 origin-top-right absolute right-0 mt-2 w-48 rounded shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                            {({ active }) => (
                                <NavLink
                                    key={item.name}
                                    to={item.href}
                                    onClick={(e) =>
                                        item.callback && item.callback(e)
                                    }
                                    className={classNames(
                                        active ? 'bg-gray-100' : '',
                                        'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                >
                                    {item.name}
                                </NavLink>
                            )}
                        </Menu.Item>
                    ))}
                    <Menu.Item key="logout">
                        {({ active }) => (
                            <NavLink
                                key="logout"
                                to="#"
                                onClick={logout}
                                className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                )}
                            >
                                Sign out
                            </NavLink>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default UserProfileDropdown;
