import React from 'react';
import {
    Flyout,
    VictoryAxis,
    VictoryChart,
    VictoryGroup,
    VictoryLine,
    VictoryScatter,
    VictoryTooltip,
    VictoryVoronoiContainer,
} from 'victory';
import ChartTheme from './ChartTheme';

const UsersChart = () => {
    return (
        <div className="hidden md:block">
            <VictoryChart
                height={200}
                width={800}
                theme={ChartTheme}
                animate={{
                    duration: 1000,
                    onLoad: { duration: 100 },
                }}
                containerComponent={<VictoryVoronoiContainer />}
            >
                <VictoryAxis style={{grid: { stroke: 'transparent', strokeWidth: 0}}} />
                <VictoryAxis dependentAxis />
                <VictoryGroup
                    color="#CCC6C4"
                    // labels={({ datum }) => `Users: ${datum.y}`}
                    // labelComponent={<VictoryTooltip style={{ fontSize: 10 }} />}
                    data={[
                        { x: 'Dec 19', y: 1 },
                        { x: 'Dec 20', y: 1 },
                        { x: 'Dec 21', y: 2 },
                        { x: 'Dec 22', y: 3 },
                        { x: 'Dec 23', y: 2 },
                        { x: 'Dec 24', y: 3 },
                        { x: 'Dec 25', y: 2 },
                    ]}
                >

                    <VictoryLine
                        interpolation="natural"
                        style={{
                            data: {
                                strokeWidth: '2',
                                strokeDasharray: '10, 5',
                            },
                        }}
                    />
                    <VictoryScatter
                        // animate={{
                        //     duration: 800,
                        //     onLoad: { duration: 100 },
                        // }}
                        size={({ active }) => (active ? 0 : 0)}
                    />
                </VictoryGroup>
                <VictoryGroup
                    color="#010101"
                    labels={({ datum }) => `Users: ${datum.y}`}
                    labelComponent={<VictoryTooltip style={{ fontSize: 10 }} />}
                    data={[
                        { x: 'Dec 19', y: 2 },
                        { x: 'Dec 20', y: 2 },
                        { x: 'Dec 21', y: 4 },
                        { x: 'Dec 22', y: 6 },
                        { x: 'Dec 23', y: 7 },
                        { x: 'Dec 24', y: 12 },
                        { x: 'Dec 25', y: 15 },
                    ]}
                >
                    <VictoryLine
                        interpolation="natural"
                        style={{
                            data: {
                                // fill: "#010101",
                                // fillOpacity: '0.2',
                                strokeWidth: '2',
                                // strokeDasharray: '10, 5',
                            },
                        }}
                    />
                    <VictoryScatter
                        animate={true}
                        size={({ active }) => (active ? 5 : 0)}
                    />
                </VictoryGroup>
            </VictoryChart>
        </div>
    );
};

export default UsersChart;
