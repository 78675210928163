import React from 'react';
import iconProd from '../../assets/images/logo-white.svg';
import iconDev from '../../assets/images/logo-white.svg';

const HeaderLogo = (props) => {
    const logo = process.env.NODE_ENV === 'production' ? iconProd : iconDev;

    return (
        <>
            <img
                className="block lg:hidden h-8 w-auto p-1"
                src={logo}
                alt="TTV"
            />
            <img
                className="hidden lg:block h-10 w-auto p-1"
                src={logo}
                alt="TTV"
            />
        </>
    );
};

export default HeaderLogo;
