import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
// Pages
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import ContentPage from './pages/ContentPage';
import UsersPage from './pages/UsersPage';
// Styles
import './assets/css/main.css';
import { UserProfilePage } from './pages/UserProfilePage';
import ThemesPage from './pages/ThemesPage';
import { Auth0Provider } from '@auth0/auth0-react';
// @ts-ignore
import authConfig from 'src/config/auth';
import GraphqlPage from './pages/GraphqlPage';
import UserDetailPage from './pages/UserDetailPage';

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Auth0Provider
        domain={authConfig.domain}
        clientId={authConfig.clientId}
        // @ts-ignore
        redirectUri={window.location.origin}
        audience={authConfig.audience}
        scope="read:current_user read:current_user_metadata"
    >
        <BrowserRouter>
            <Routes>
                {/* Login Pages */}
                <Route path="/login" element={<LoginPage />} />
                {/* Application Pages (Authenticated) */}
                <Route path="/">
                    <Route index element={<HomePage />} />
                    <Route path="content" element={<ContentPage />} />
                    <Route path="themes" element={<ThemesPage />} />
                    <Route path="users" element={<UsersPage />} />
                    <Route path="users/:userID" element={<UserDetailPage />} />
                    <Route path="user">
                        <Route
                            index
                            element={<Navigate replace to="/user/profile" />}
                        />
                        <Route path="profile" element={<UserProfilePage />} />
                    </Route>
                </Route>
                <Route path="graphql" element={<GraphqlPage />} />

                {/* Utility Pages */}
                {/* <Route exact path="/recover"><RecoverPage /></Route> */}
                {/* <Route exact path="/failure"><FailPage /></Route> */}
            </Routes>
        </BrowserRouter>
    </Auth0Provider>
);
