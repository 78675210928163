import React, { useState } from 'react';
import Datepicker from 'react-tailwindcss-datepicker';
import HomePageSubtitle from './HomePageSubtitle';

const HomePageActionMenu = () => {
    const today = new Date();
    const [value, setValue] = useState({
        startDate: today.setDate(today.getDate()-7),
        endDate: new Date()
    });
    
    const handleValueChange = (newValue) => {
        console.log("newValue:", newValue);
        setValue(newValue);
    }

    return (
        <div className="flex flex-col lg:ml-4">
            <Datepicker
                primaryColor='amber'
                showFooter={false}
                showShortcuts={true}
                useRange={true}
                asSingle={false}
                readOnly={true}
                // @ts-ignore
                value={value}
                onChange={handleValueChange}
                startFrom={new Date()}
                // displayFormat=""
            />
            {/* <HomePageSubtitle /> */}
        </div>
    );
};

export default HomePageActionMenu;
