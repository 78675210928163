import GraphiQL from 'graphiql';
import React, { useContext, useEffect, useState } from 'react';
import Application from 'src/layout/Application';
import 'graphiql/graphiql.min.css';
import { UserContext } from 'src/providers/UserProvider';
import { useAuth0 } from '@auth0/auth0-react';

// const graphqlEndpoint = 'https://api.withatlas.dev/graphql';
const graphqlEndpoint =
    process.env.NODE_ENV === 'production'
    // true
        ? 'https://content.withatlas.dev/graphql'
        : 'http://localhost:1337/graphql';

const GraphqlPage = () => {
    // const userCtx = useContext(UserContext);
    const [accessToken, setAccessToken] = useState('');

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        getAccessTokenSilently({
            audience: 'https://content.withatlas.dev/graphql',
            scope: 'read:graphql',
        }).then(async (accessToken) => {
            console.log('accessToken', accessToken);
            setAccessToken(accessToken);
        });
    }, []);

    return (
        <Application>
            <div className="flex h-screen w-screen top-0">
                <GraphiQL
                
                    fetcher={async (graphQLParams) => {
                        const data = await fetch(graphqlEndpoint, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + accessToken,
                            },
                            body: JSON.stringify(graphQLParams),
                            // credentials: 'same-origin',
                            mode: 'no-cors',
                        });
                        return data.json().catch(() => data.text());
                    }}
                />
            </div>
        </Application>
    );
};

export default GraphqlPage;
