import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HorizontalTabs = ({ tabs }) => {
    const [data, setData] = useState(tabs);

    const switchTab = (e, name) => {
        e.preventDefault();
        setData(
            data.map((item) => {
                if (item.name === name) {
                    item.current = true;
                    item.callback && item.callback();
                } else {
                    item.current = false;
                }
                return item;
            })
        );
    };

    return (
        <div>
            <div className="sm:hidden">
                <label htmlFor="question-tabs" className="sr-only">
                    Select a tab
                </label>
                <select
                    id="question-tabs"
                    onChange={(e) => switchTab(e, e.currentTarget.value)}
                    className="block w-full rounded border-atlas-background text-base font-medium text-gray-900 shadow-sm focus:border-gray-500 focus:ring-gray-500"
                    defaultValue={data.find((tab) => tab.current).name}
                >
                    {data.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                    ))}
                </select>
            </div>
            <div className="hidden sm:block">
                <nav
                    className="relative z-0 rounded shadow flex divide-x divide-gray-200"
                    aria-label="Tabs"
                >
                    {data.map((tab, tabIdx) => (
                        <a
                            key={tab.name}
                            href="#"
                            onClick={(e) => switchTab(e, tab.name)}
                            aria-current={tab.current ? 'page' : undefined}
                            className={classNames(
                                tab.current
                                    ? 'text-atlas-primary font-semibold'
                                    : 'text-gray-500 hover:text-gray-700',
                                tabIdx === 0 ? 'rounded-l-lg' : '',
                                tabIdx === tabs.length - 1
                                    ? 'rounded-r-lg'
                                    : '',
                                'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-6 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                            )}
                        >
                            <span>{tab.name}</span>
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    tab.current
                                        ? 'bg-atlas-primary'
                                        : 'bg-transparent',
                                    'absolute inset-x-0 bottom-0 h-0.5'
                                )}
                            />
                        </a>
                    ))}
                </nav>
            </div>
        </div>
    );
};

HorizontalTabs.propTypes = {};

export default HorizontalTabs;
