import React, { Fragment, useState } from 'react';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/20/solid';
import UsersChart from '../Charts/UsersChart';
import classNames from 'classnames';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { Transition } from '@headlessui/react';

const DailyActiveUsersStats = () => {
    const [showDauTooltip, setShowDauTooltip] = useState(false);
    const [mouseLocation, setMouseLocation] = useState({ x: 0, y: 0 });

    const handleDauHoverEnter = (event) => {
        setMouseLocation({
            x: event.pageX,
            y: event.pageY,
        });
        setShowDauTooltip(true);
    };

    const handleDauHoverLeave = (event) => {
        setShowDauTooltip(false);
    };

    const dau = {
        count: 3274,
        change: '300%',
        changeType: 'increase',
    };

    return (
        <div className="mt-6 p-4 overflow-hidden rounded-lg bg-white shadow">
            <h2 className="mx-2 mt-2 text-lg leading-6 text-gray-700 sm:truncate sm:tracking-tight">
                Daily Active Users{' '}
                <QuestionMarkCircleIcon
                    className="inline-flex text-gray-400"
                    onMouseEnter={handleDauHoverEnter}
                    onMouseLeave={handleDauHoverLeave}
                    width={20}
                    height={20}
                />
                <div
                    className={classNames(
                        showDauTooltip ? 'block' : 'hidden',
                        'px-4 py-2 border border-gray-200 rounded shadow-md absolute top-0 bg-white'
                    )}
                    style={{
                        top: mouseLocation.y - 90,
                        left: mouseLocation.x + 20,
                    }}
                >
                    <h3 className="font-maisonneuebook text-md font-bold leading-6">
                        Daily Active Users (DAU)
                    </h3>
                    <p className="font-maisonneuebook text-sm font-medium">
                        Unique users with a successful authentication or
                        authorization activity.
                    </p>
                </div>
            </h2>
            <h2 className="mx-2 my-2 justify-center text-2xl font-bold leading-6 text-gray-700 tracking-wide">
                {dau.count}
                <div
                    className={classNames(
                        dau.changeType === 'increase'
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800',
                        'ml-2 inline-flex px-2.5 py-0.5 rounded text-xs font-medium'
                    )}
                >
                    {dau.changeType === 'increase' ? (
                        <ArrowUpIcon
                            className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-atlas-green"
                            aria-hidden="true"
                        />
                    ) : (
                        <ArrowDownIcon
                            className="-ml-1 mr-0.5 h-4 w-4 flex-shrink-0 self-center text-atlas-red"
                            aria-hidden="true"
                        />
                    )}

                    <span className="sr-only">
                        {' '}
                        {dau.changeType === 'increase'
                            ? 'Increased'
                            : 'Decreased'}{' '}
                        by{' '}
                    </span>
                    {dau.change}
                </div>
            </h2>
            <div className="">
                <UsersChart />
            </div>
        </div>
    );
};

export default DailyActiveUsersStats;
