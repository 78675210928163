import React, { useContext } from 'react';
import { UserContext } from 'src/providers/UserProvider';

const ProfileSection = (props) => {
    const { user, metadata } = useContext(UserContext);
    console.log(metadata);
    return (
        <section aria-labelledby="profile-details-heading">
            <form action="#" method="POST">
                <div className="shadow rounded sm:overflow-hidden bg-white">
                    <div className="bg-white px-4 sm:px-6 pt-2 pb-4 sm:pb-8">
                        <div className="mt-6 space-y-8 divide-y divide-y-gray-200">
                            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                                <div className="sm:col-span-6">
                                    <h2 className="text-2xl font-bold text-gray-700">
                                        Profile
                                    </h2>
                                    <p className="mt-1 text-sm text-gray-500">
                                        This information will be displayed
                                        publicly so be careful what you share.
                                    </p>
                                </div>

                                <div className="sm:col-span-6">
                                    <label
                                        htmlFor="photo"
                                        className="block text-sm font-medium text-gray-900"
                                    >
                                        Photo
                                    </label>
                                    <div className="mt-1 flex items-center">
                                        <img
                                            className="inline-block h-24 w-24 lg:h-36 lg:w-36 rounded-full"
                                            src={user.picture}
                                            alt={user.email}
                                        />
                                        <div className="ml-4 flex">
                                            <div className="relative bg-white py-2 px-3 border border-gray-300 rounded shadow-sm flex items-center cursor-pointer hover:bg-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-50 focus-within:ring-gray-500">
                                                <label
                                                    htmlFor="user-photo"
                                                    className="relative text-sm font-medium text-gray-900 pointer-events-none"
                                                >
                                                    <span>Change</span>
                                                    <span className="sr-only">
                                                        {' '}
                                                        user photo
                                                    </span>
                                                </label>
                                                <input
                                                    id="user-photo"
                                                    name="user-photo"
                                                    type="file"
                                                    className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded"
                                                />
                                            </div>
                                            <button
                                                type="button"
                                                className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded text-sm font-medium text-gray-900 hover:text-gray-700 focus:outline-none focus:border-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-gray-500"
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="first-name"
                                        className="block text-sm font-medium text-gray-900"
                                    >
                                        First name
                                    </label>
                                    <input
                                        type="text"
                                        name="first-name"
                                        id="first-name"
                                        autoComplete="given-name"
                                        value={metadata.givenName}
                                        className="mt-1 block w-full border-gray-300 rounded shadow-sm text-gray-900 sm:text-sm focus:ring-gray-500 focus:border-gray-500"
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="last-name"
                                        className="block text-sm font-medium text-gray-900"
                                    >
                                        Last name
                                    </label>
                                    <input
                                        type="text"
                                        name="last-name"
                                        id="last-name"
                                        autoComplete="family-name"
                                        value={metadata.familyName}
                                        className="mt-1 block w-full border-gray-300 rounded shadow-sm text-gray-900 sm:text-sm focus:ring-gray-500 focus:border-gray-500"
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="email-address"
                                        className="block text-sm font-medium text-gray-900"
                                    >
                                        Email address
                                    </label>
                                    <input
                                        type="text"
                                        name="email-address"
                                        id="email-address"
                                        autoComplete="email"
                                        className="mt-1 block w-full border-gray-300 rounded shadow-sm text-gray-900 sm:text-sm focus:ring-gray-500 focus:border-gray-500"
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="phone-number"
                                        className="block text-sm font-medium text-gray-900"
                                    >
                                        Phone number
                                    </label>
                                    <input
                                        type="text"
                                        name="phone-number"
                                        id="phone-number"
                                        autoComplete="tel"
                                        className="mt-1 block w-full border-gray-300 rounded shadow-sm text-gray-900 sm:text-sm focus:ring-gray-500 focus:border-gray-500"
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="country"
                                        className="block text-sm font-medium text-gray-900"
                                    >
                                        Country
                                    </label>
                                    <select
                                        id="country"
                                        name="country"
                                        autoComplete="country-name"
                                        className="mt-1 block w-full border-gray-300 rounded shadow-sm text-gray-900 sm:text-sm focus:ring-gray-500 focus:border-gray-500"
                                    >
                                        <option />
                                        <option>United States</option>
                                        <option>Canada</option>
                                        <option>Mexico</option>
                                    </select>
                                </div>

                                <div className="sm:col-span-3">
                                    <label
                                        htmlFor="language"
                                        className="block text-sm font-medium text-gray-900"
                                    >
                                        Language
                                    </label>
                                    <input
                                        type="text"
                                        name="language"
                                        id="language"
                                        className="mt-1 block w-full border-gray-300 rounded shadow-sm text-gray-900 sm:text-sm focus:ring-gray-500 focus:border-gray-500"
                                    />
                                </div>

                                <p className="text-sm text-gray-500 sm:col-span-6">
                                    This account was created on{' '}
                                    <time dateTime="2017-01-05T20:35:40">
                                        {user.createdAt}
                                    </time>
                                    .
                                </p>
                            </div>

                            <div className="pt-8 flex justify-end">
                                <button
                                    type="button"
                                    className="bg-white py-2 px-4 border border-gray-300 rounded shadow-sm text-sm font-medium text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-atlas-primary hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    );
};

export default ProfileSection;
