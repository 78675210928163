import React, { useState } from 'react';
import {
    CogIcon,
    CreditCardIcon,
    UserCircleIcon,
} from '@heroicons/react/24/outline';
import Application from '../../layout/Application';
import VerticalTabs from 'src/components/UI/VerticalTabs';
import ProfileSection from './ProfileSection';
import AccountSection from './AccountSection';
import BillingSection from './BillingSection';
import Header from 'src/components/Header';

export const UserProfilePage = (props) => {
    const [profileNavigation, setProfileNavigation] = useState([
        {
            name: 'Profile',
            href: '#',
            icon: UserCircleIcon,
            current: true,
            callback: () => setTab('Profile'),
        },
        {
            name: 'Account',
            href: '#',
            icon: CogIcon,
            current: false,
            callback: () => setTab('Account'),
        },
        {
            name: 'Billing',
            href: '#',
            icon: CreditCardIcon,
            current: false,
            callback: () => setTab('Billing'),
        },
    ]);

    const setTab = (name) => {
        setProfileNavigation(
            profileNavigation.map((item) => {
                item.current = name === item.name;
                return item;
            })
        );
    };

    const selectedTab = () => {
        let selectedElement = null;
        profileNavigation.forEach((element) => {
            if (element.current) {
                selectedElement = element;
            }
        });
        return selectedElement;
    };

    return (
        <Application>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <Header title="User Profile" />
                <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
                    <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
                        <VerticalTabs tabs={profileNavigation} />
                    </aside>

                    <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
                        {selectedTab().name === 'Profile' && <ProfileSection />}
                        {selectedTab().name === 'Account' && <AccountSection />}
                        {selectedTab().name === 'Billing' && <BillingSection />}
                    </div>
                </div>
            </div>
        </Application>
    );
};
