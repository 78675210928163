import React from 'react';
import Header from 'src/components/Header';
import UserDetail from 'src/components/UserDetail';
import Application from 'src/layout/Application';
// import UserActionMenu from './UserActionMenu';

const UserDetailPage = (props) => {
    return (
        <Application>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                {/* <Header title="Users" actions={<UserActionMenu />} /> */}
                <UserDetail />
            </div>
        </Application>
    );
};

export default UserDetailPage;
