import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect, useState } from 'react';
import Loading from 'src/components/Loading';
import { NotificationContext } from './NotificationProvider';
// @ts-ignore
import authConfig from '../config/auth.json';

export const UserContext = React.createContext(undefined);

const UserProvider = ({ children }) => {
    const {
        getAccessTokenSilently,
        loginWithRedirect,
        isAuthenticated,
        error,
        user,
        isLoading,
        logout,
    } = useAuth0();
    const [loading, setLoading] = useState(true);
    const [authUser, setAuthUser] = useState(undefined);
    const [accessToken, setAccessToken] = useState(undefined);
    const [metadata, setMetadata] = useState({});
    const { addNotification } = useContext(NotificationContext);

    useEffect(() => {
        if (!isLoading) {
            if (error) {
                addNotification({
                    title: 'Login Error',
                    body: error.message,
                    type: 'error',
                });
            }
            if (!user && !isAuthenticated) {
                loginWithRedirect()
                    .then(async () => {
                        setAuthUser(user);
                        setLoading(false);
                    })
                    .catch((err) => {
                        addNotification({
                            title: 'Unable to login',
                            body: err.message,
                            type: 'error',
                        });
                        setLoading(false);
                    });
            } else if (user && isAuthenticated) {
                setAuthUser(user);
                setLoading(false);
            }
        } else {
            setLoading(true);
        }
    }, [isLoading, isAuthenticated, error, user]);

    useEffect(() => {
        let active = true;

        if (active && !isLoading && isAuthenticated) {
            getAccessTokenSilently({
                audience: authConfig.mgmtAudience,
                scope: 'read:current_user read:current_user_metadata',
            }).then(async (accessToken) => {
                console.log('Got accessToken: ', accessToken);
                setAccessToken(accessToken);
                const userDetailsByIdUrl = `https://${authConfig.mgmtDomain}/api/v2/users/${user.sub}`;
                const metadataResponse = await fetch(userDetailsByIdUrl, {
                    // mode: 'no-cors',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                console.log(metadataResponse);
                if (metadataResponse.ok) {
                    const { metadata } = await metadataResponse.json();
                    setMetadata(metadata);
                }
            });
        }
        return () => {
            active = false;
        };
    }, [user, isLoading, isAuthenticated]);

    const handleLogout = () => {
        setLoading(true);
        setAuthUser(undefined);
        logout({ returnTo: window.location.origin });
    };

    if (loading) {
        return <Loading showText={false} />;
    }

    return (
        <UserContext.Provider
            value={{
                user: authUser,
                setUser: setAuthUser,
                logout: handleLogout,
                accessToken: accessToken,
                metadata: metadata,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const GetCurrentUser = () => {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('GetCurrentUser must be used within a UserProvider');
    }
    return context.user;
};

export default UserProvider;
