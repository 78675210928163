import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React from 'react';

const SearchBar = (props) => {
    return (
        <div className="max-w-lg w-full lg:max-w-xs">
            <label htmlFor="search" className="sr-only">
                Search
            </label>
            <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <MagnifyingGlassIcon
                        className="h-5 w-5 text-atlas-primary"
                        aria-hidden="true"
                    />
                </div>
                <input
                    id="search"
                    name="search"
                    className="block w-full pl-10 pr-3 py-2 border border-transparent rounded leading-5 bg-atlas-secondary text-atlas-primary placeholder-gray-700 focus:outline-none focus:bg-white focus:border-white focus:ring-white focus:text-atlas-primary sm:text-sm"
                    placeholder="Search"
                    type="search"
                />
            </div>
        </div>
    );
};

export default SearchBar;
