import React from 'react';
import DropdownButton from 'src/components/UI/DropdownButton';

const UserActionMenu = (props) => {
    return (
        <div className="wflex lg:ml-4">
            <DropdownButton title="Actions" items={[
                    { name: 'Create User', href: '#' },
                    // { name: 'Create Blog ', href: '#' },
                ]} />
        </div>
    );
};

UserActionMenu.propTypes = {};

export default UserActionMenu;
