import React, { Fragment } from 'react';
import DropdownButton from 'src/components/UI/DropdownButton';

const ThemeActionMenu = (props) => {
    return (
        <div className="flex lg:ml-4">
            <DropdownButton
                title="Actions"
                items={[
                    { name: 'Create Theme', href: '#' },
                    // { name: 'Create Blog ', href: '#' },
                ]}
            />
        </div>
    );
};

ThemeActionMenu.propTypes = {};

export default ThemeActionMenu;
