var _assign2 = _interopRequireDefault(require('lodash/assign'));

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : { default: obj };
}

// *
// * Colors
// *
var yellow200 = '#FFF59D';
var deepOrange600 = '#F4511E';
var lime300 = '#DCE775';
var lightGreen500 = '#8BC34A';
var teal700 = '#00796B';
var cyan900 = '#006064';
var colors = [
    deepOrange600,
    yellow200,
    lime300,
    lightGreen500,
    teal700,
    cyan900,
];
var blueGrey50 = '#F2EFEB';
var blueGrey300 = '#90A4AE';
var blueGrey700 = '#455A64';
var grey900 = '#212121'; // *
// * Typography
// *

var sansSerif = "'Inter, Helvetica Neue', 'Helvetica', sans-serif";
var letterSpacing = 'normal';
var fontSize = 9; // *
// * Layout
// *

var padding = 4;
var baseProps = {
    width: 350,
    height: 350,
    padding: 32,
}; // *
// * Labels
// *

var baseLabelStyles = {
    fontFamily: sansSerif,
    fontSize: fontSize,
    letterSpacing: letterSpacing,
    padding: padding,
    fill: blueGrey700,
    stroke: 'transparent',
    strokeWidth: 0,
};
var centeredLabelStyles = (_assign2.default)(
    {
        textAnchor: 'middle',
    },
    baseLabelStyles
); // *
// * Strokes
// *

var strokeDasharray = '10, 5';
var strokeLinecap = 'round';
var strokeLinejoin = 'round';
var ChartTheme = {
    area: (_assign2.default)(
        {
            style: {
                data: {
                    fill: grey900,
                },
                labels: baseLabelStyles,
            },
        },
        baseProps
    ),
    axis: (_assign2.default)(
        {
            style: {
                axis: {
                    fill: 'transparent',
                    stroke: blueGrey300,
                    strokeWidth: 1,
                    strokeLinecap: strokeLinecap,
                    strokeLinejoin: strokeLinejoin,
                },
                axisLabel: (_assign2.default)({}, centeredLabelStyles, {
                    padding: padding,
                    stroke: 'transparent',
                }),
                grid: {
                    fill: 'none',
                    stroke: blueGrey50,
                    // strokeDasharray: strokeDasharray,
                    strokeLinecap: strokeLinecap,
                    strokeLinejoin: strokeLinejoin,
                    pointerEvents: 'painted',
                },
                ticks: {
                    fill: 'transparent',
                    size: 5,
                    stroke: blueGrey300,
                    strokeWidth: 1,
                    strokeLinecap: strokeLinecap,
                    strokeLinejoin: strokeLinejoin,
                },
                tickLabels: (_assign2.default)({}, baseLabelStyles, {
                    fill: blueGrey700,
                }),
            },
        },
        baseProps
    ),
    polarDependentAxis: (_assign2.default)({
        style: {
            ticks: {
                fill: 'transparent',
                size: 1,
                stroke: 'transparent',
            },
        },
    }),
    bar: (_assign2.default)(
        {
            style: {
                data: {
                    fill: blueGrey700,
                    padding: padding,
                    strokeWidth: 0,
                },
                labels: baseLabelStyles,
            },
        },
        baseProps
    ),
    boxplot: (_assign2.default)(
        {
            style: {
                max: {
                    padding: padding,
                    stroke: blueGrey700,
                    strokeWidth: 1,
                },
                maxLabels: (_assign2.default)({}, baseLabelStyles, {
                    padding: 3,
                }),
                median: {
                    padding: padding,
                    stroke: blueGrey700,
                    strokeWidth: 1,
                },
                medianLabels: (_assign2.default)({}, baseLabelStyles, {
                    padding: 3,
                }),
                min: {
                    padding: padding,
                    stroke: blueGrey700,
                    strokeWidth: 1,
                },
                minLabels: (_assign2.default)({}, baseLabelStyles, {
                    padding: 3,
                }),
                q1: {
                    padding: padding,
                    fill: blueGrey700,
                },
                q1Labels: (_assign2.default)({}, baseLabelStyles, {
                    padding: 3,
                }),
                q3: {
                    padding: padding,
                    fill: blueGrey700,
                },
                q3Labels: (_assign2.default)({}, baseLabelStyles, {
                    padding: 3,
                }),
            },
            boxWidth: 20,
        },
        baseProps
    ),
    candlestick: (_assign2.default)(
        {
            style: {
                data: {
                    stroke: blueGrey700,
                },
                labels: (_assign2.default)({}, baseLabelStyles, {
                    padding: 5,
                }),
            },
            candleColors: {
                positive: '#ffffff',
                negative: blueGrey700,
            },
        },
        baseProps
    ),
    chart: baseProps,
    errorbar: (_assign2.default)(
        {
            borderWidth: 8,
            style: {
                data: {
                    fill: 'transparent',
                    opacity: 1,
                    stroke: blueGrey700,
                    strokeWidth: 2,
                },
                labels: baseLabelStyles,
            },
        },
        baseProps
    ),
    group: (_assign2.default)(
        {
            colorScale: colors,
        },
        baseProps
    ),
    histogram: (_assign2.default)(
        {
            style: {
                data: {
                    fill: blueGrey700,
                    stroke: grey900,
                    strokeWidth: 2,
                },
                labels: baseLabelStyles,
            },
        },
        baseProps
    ),
    legend: {
        colorScale: colors,
        gutter: 10,
        orientation: 'vertical',
        titleOrientation: 'top',
        style: {
            data: {
                type: 'circle',
            },
            labels: baseLabelStyles,
            title: (_assign2.default)({}, baseLabelStyles, {
                padding: 5,
            }),
        },
    },
    line: (_assign2.default)(
        {
            style: {
                data: {
                    fill: 'transparent',
                    opacity: 1,
                    stroke: blueGrey700,
                    strokeWidth: 3,
                },
                labels: {
                    ...baseLabelStyles,
                    stroke: 'transparent',
                    strokeWidth: 0
                }
            },
        },
        baseProps
    ),
    pie: (_assign2.default)(
        {
            colorScale: colors,
            style: {
                data: {
                    padding: padding,
                    stroke: blueGrey50,
                    strokeWidth: 1,
                },
                labels: (_assign2.default)({}, baseLabelStyles, {
                    padding: 20,
                }),
            },
        },
        baseProps
    ),
    scatter: (_assign2.default)(
        {
            style: {
                data: {
                    fill: blueGrey700,
                    opacity: 1,
                    stroke: 'transparent',
                    strokeWidth: 0,
                },
                labels: baseLabelStyles,
            },
        },
        baseProps
    ),
    stack: (_assign2.default)(
        {
            colorScale: colors,
        },
        baseProps
    ),
    tooltip: {
        style: (_assign2.default)({}, baseLabelStyles, {
            padding: 0,
            pointerEvents: 'none',
        }),
        flyoutStyle: {
            stroke: grey900,
            strokeWidth: 1,
            fill: '#f0f0f0',
            pointerEvents: 'none',
        },
        flyoutPadding: 5,
        cornerRadius: 5,
        pointerLength: 10,
    },
    voronoi: (_assign2.default)(
        {
            style: {
                data: {
                    fill: 'transparent',
                    stroke: 'transparent',
                    strokeWidth: 0,
                },
                labels: (_assign2.default)({}, baseLabelStyles, {
                    padding: 5,
                    pointerEvents: 'none',
                }),
                flyout: {
                    stroke: grey900,
                    strokeWidth: 1,
                    fill: '#f0f0f0',
                    pointerEvents: 'none',
                },
            },
        },
        baseProps
    ),
};

export default ChartTheme;