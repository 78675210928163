export const navigation = [
    { name: "Dashboard", href: "/", current: true },
    { name: "Content", href: "/content", current: false },
    { name: "Themes", href: "/themes", current: false },
    { name: "Users", href: "/users", current: false },
    { name: "Dev Console", href: "/graphql", current: false},
];

export const userNavigation = [
    { name: 'My Profile', href: '/user/profile' },
    // { name: 'Sign out', href: '#', callback: signOutUser },
];