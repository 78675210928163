import classNames from 'classnames';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';

const people = [
    {
        id: "a7f7c776-56e3-4b8a-9e9c-7b79dc3b0ada",
        name: 'Steve Rude',
        emailVerified: true,
        smsVerified: true,
        email: 'steve@atlasfinancial.com',
        role: 'Admin',
        image: 'https://s.gravatar.com/avatar/30c62aaa6bc8bbb109bf5152f5a980b9?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fst.png',
    },
    {
        id: "a7f7c776-56e3-4b8a-9e9c-7b79dc3b0adb",
        name: 'Steve Rude',
        emailVerified: true,
        smsVerified: true,
        email: 'steve@atlasfinancial.com',
        role: 'Admin',
        image: 'https://s.gravatar.com/avatar/30c62aaa6bc8bbb109bf5152f5a980b9?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fst.png',
    },
    {
        id: "a7f7c776-56e3-4b8a-9e9c-7b79dc3b0adc",
        name: 'Steve Rude',
        emailVerified: false,
        smsVerified: false,
        email: 'steve@atlasfinancial.com',
        role: 'Admin',
        image: 'https://s.gravatar.com/avatar/30c62aaa6bc8bbb109bf5152f5a980b9?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fst.png',
    },
    {
        id: "a7f7c776-56e3-4b8a-9e9c-7b79dc3b0add",
        name: 'Steve Rude',
        emailVerified: true,
        smsVerified: false,
        email: 'steve@atlasfinancial.com',
        role: 'Admin',
        image: 'https://s.gravatar.com/avatar/30c62aaa6bc8bbb109bf5152f5a980b9?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fst.png',
    },
    {
        id: "a7f7c776-56e3-4b8a-9e9c-7b79dc3b0ade",
        name: 'Steve Rude',
        emailVerified: true,
        smsVerified: false,
        email: 'steve@atlasfinancial.com',
        role: 'Admin',
        image: 'https://s.gravatar.com/avatar/30c62aaa6bc8bbb109bf5152f5a980b9?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fst.png',
    },
];

const UserList = (props) => {
    return (
        <div className="my-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900 uppercase">
                        Recent Users
                    </h1>
                    {/* <p className="mt-2 text-sm text-gray-700">
                        A list of all the users in your account including their
                        name, title, email and role.
                    </p> */}
                </div>
            </div>
            <div className="mt-4 mx-4 lg:mx-0 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                        >
                                            Name
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:block"
                                        >
                                            Verification
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Status
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                        >
                                            Role
                                        </th>
                                        <th
                                            scope="col"
                                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                        >
                                            <span className="sr-only">
                                                Edit
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {people.map((person) => (
                                        <tr key={person.id}>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                                <div className="flex items-center">
                                                    <div className="h-10 w-10 flex-shrink-0">
                                                        <img
                                                            className="h-10 w-10 rounded-full"
                                                            src={person.image}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="ml-4">
                                                        <div className="font-medium text-gray-900">
                                                            {person.name}
                                                        </div>
                                                        <div className="text-gray-500">
                                                            {person.email}
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="flex-row space-x-2 whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <span
                                                    className={classNames(
                                                        'inline-flex rounded-md px-2 text-xs font-semibold leading-5 ',
                                                        person.emailVerified
                                                            ? 'text-green-800 bg-green-100'
                                                            : 'text-red-800 bg-red-100'
                                                    )}
                                                >
                                                    Email
                                                </span>
                                                <span
                                                    className={classNames(
                                                        'inline-flex rounded-md px-2 text-xs font-semibold leading-5 ',
                                                        person.smsVerified
                                                            ? 'text-green-800 bg-green-100'
                                                            : 'text-red-800 bg-red-100'
                                                    )}
                                                >
                                                    SMS
                                                </span>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                <span className="inline-flex rounded-md bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                                    Active
                                                </span>
                                            </td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                {person.role}
                                            </td>
                                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                <NavLink
                                                    to={"/users/" + person.id}
                                                    className="text-atlas-primary hover:text-indigo-900"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        strokeWidth={1.5}
                                                        stroke="currentColor"
                                                        className="w-6 h-6"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                                                        />
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                        />
                                                    </svg>

                                                    <span className="sr-only">
                                                        , {person.name}
                                                    </span>
                                                </NavLink>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserList;
