import React from 'react';
import Feed from 'src/components/Feed';
import Header from 'src/components/Header';
import Application from 'src/layout/Application';
import ContentActionMenu from './ContentActionMenu';

const ContentPage = (props) => {
    return (
        <Application>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <Header title="Content" actions={<ContentActionMenu />} />
                <Feed key="content-page" />
            </div>
        </Application>
    );
};

export default ContentPage;
