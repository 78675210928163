import React, { createContext, useState } from 'react';
import NotificationPopup from 'src/components/Notifications/NotificationPopup';

export const NotificationContext = createContext({
    notifications: [],
    addNotification: (notification) => {},
    removeNotification: (id) => {},
    panelOpen: false,
    setPanelOpen: (open) => {}
});

const NotificationProvider = (props) => {
    const [curItem, setCurItem] = useState(null);
    const [panelOpen, setPanelOpen] = useState(false);
    const [notifications, setNotifications] = useState([
        // { id: 1, title: "This is a notification.", body: "This is the body", type: "success"},
        // { id: 2, title: "This is a warning notification too.", body: "This is the body too", type: "warning"},
        // { id: 3, title: "This is an error notification too.", body: "This is the body too", type: "error"},
    ]);

    const functions = {
        addNotification: (notification) => addNotification(notification),
        removeNotification: (id) => {
            const newNotifidations = notifications
                .map((item, i) => {
                    if (item.id !== id) {
                        return item;
                    }
                    return null;
                })
                .filter((f) => f !== null);
            setNotifications(newNotifidations);
        },
        setPanelOpen: (open) => {
            setPanelOpen(open);
        },
        notify: (notification) => {
            addNotification(notification);
        }
    };

    const addNotification = (notification) => {
        notification.id = `${Date.now()}-${notification.id ?? ''}`;
        const count = notifications.unshift(notification);
        
        if (count > 0) {
            setCurItem(notification);
            setTimeout(() => {
                setCurItem(null);
            }, 5000);
            setNotifications(notifications);
        }
    };

    return (
        <NotificationContext.Provider value={{
            panelOpen: panelOpen,
            notifications: notifications,
            ...functions
        }}>
            {props.children}
            <NotificationPopup item={curItem} />
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;
