import React from 'react';
import DailyActiveUsersStats from 'src/components/DashboardStats/DailyActiveUsersStats';
import UserStats from 'src/components/DashboardStats/UserStats';
import Header from 'src/components/Header';
import UserList from 'src/components/UserList';
import Application from 'src/layout/Application';
import UserActionMenu from './UserActionMenu';

const UsersPage = (props) => {
    return (
        <Application>
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
                <Header title="Users" actions={<UserActionMenu />} />
                <UserStats />
                {/* <DailyActiveUsersStats /> */}
                <UserList />
            </div>
        </Application>
    );
};

export default UsersPage;
