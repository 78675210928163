import React from "react";
const { CalendarIcon } = require("@heroicons/react/20/solid");

const HomePageSubtitle = () => {
    return (
        <>
            <div className="mt-2 flex flex-row-reverse items-center text-sm text-gray-500">
                <CalendarIcon
                    className="mx-2 h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                />
                Last 7 Days
            </div>
        </>
    );
};

export default HomePageSubtitle;